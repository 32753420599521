<template>
  <div class="modal">
    <div class="modal-content">
      <span @click="emit('close')" class="close">&times;</span>
      <div class="heading">Slet timeslot</div>
      <h3 class="text">
        {{ selectedTimeslotForRemoval.startHour }}:{{
          selectedTimeslotForRemoval.startMinutes
        }}
        -
        {{ selectedTimeslotForRemoval.endHour }}:{{
          selectedTimeslotForRemoval.endMinutes
        }}
      </h3>
      <button @click="emit('deleteTimeslot')">Slet</button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["selectedTimeslotForRemoval"]);
const emit = defineEmits(["close", "deleteTimeslot"]);
</script>

<style scoped>
button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  background-color: #00bae0;
  margin: 0 auto;
  width: 250px;
  margin-top: 20px;
}

.modal {
  text-align: center;
  display: block;
  position: fixed;
  z-index: 30;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 450px;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
  position: relative;
  border-radius: 4px;
  background-color: #fefefe;
  margin: auto;
  padding: 36px 20px;
  width: 80%;
  max-width: 400px;
  min-width: 300px;
}

.heading {
  font-size: 1.7rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 5px;
}

.close {
  top: 0px;
  right: 12px;
  position: absolute;
  color: #aaaaaa;
  font-size: 35px;
  cursor: pointer;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

.text {
  display: inline-block;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

@media screen and (min-width: 455px) {
  .modal {
    top: 15px;
    height: calc(100% - 30px);
    border-radius: 4px;
  }
}
</style>
